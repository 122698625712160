@import "~jquery-nice-select/css/nice-select";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';
body {
  position: static !important;
}
header {
  top: 0;
}
/* You can add global styles to this file, and also import other style files */
.toast-container .ngx-toastr {
  box-shadow: none;
}

.featuredClass{
  background: #009ddc;
  color: white;
  padding: 2px 10px;
  margin: 6px 0px;
  display: inline-block;
  border-radius: 3px;
}
.toast-container.toast-top-center .ngx-toastr {
  width: 40%;
  min-width: 200px;
}
.toast-top-center {
  top: 10px;
}
.password-field #toggler {
  top: 22px;
}

.pt-20 {
  padding-top: 20px;
}
.New-small-rounded-icons {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  box-shadow: 0px 4px 4px #110b0b4d;
  margin-right: 16px;
}
.g-recaptcha-wrapper {
  position: relative;
  border: 1px solid #ededed;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 0;
  #topHider {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px !important;
    width: 100%;
    background-color: #f9f9f9;
  }
  #rightHider {
    position: absolute;
    top: 0;
    left: 295px;
    height: 100% !important;
    width: 15px;
    background-color: #f9f9f9;
  }
  #leftHider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 2px;
    background-color: #f9f9f9;
  }
  #bottomHider {
    position: absolute;
    bottom: 1px;
    left: 0;
    height: 2px !important;
    width: 100%;
    background-color: #f9f9f9;
  }
}

#google_translate_element {
  height: 50px;
}

#google_translate_element select {
  background-color: transparent;
  color: #808080;
  border: none;
  margin-top: 17px;
  width: auto;
  outline: none;
  font-size: 14px;
}
#google_translate_element select option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}
#google_translate_element select option:hover {
  background-color: #f6f6f6;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
.bs-datepicker-body
  table
  td:not(.disabled):not(.week)
  span:not(.disabled):not(.is-other-month) {
  color: #000;
}
.theme-blue .bs-datepicker-head {
  background-color: #3895b0;
}
.bs-datepicker-body table th {
  color: #05427c;
}

:host ::ng-deep .cal-month-view .cal-day-cell::after {
  min-height: 80px !important;
}
body.overlay-active{overflow: hidden;}



.ng-select.custom-ng-select-1 .ng-select-container {
  border-radius: 0.25rem 0 0 .25rem !important;
  min-height: 41px !important;
}
.ng-select.custom-ng-select-1.bg .ng-select-container {
  background: #e9ecef;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
}

.mlfminus30{
  margin-left: -30px !important; 
  margin-right: -30px !important;
}
.star-icon-block span {
  font-size: 22px;
  color: gray !important;
  line-height: 1;
  margin-left: 4px;
}


.icon-btn:hover{
  cursor: pointer;
  font-size: x-large;
}

.icon-btn-disable{
  color: #969696;
  pointer-events: none;
}



.filter-form-wrapper .form-group .form-control {
  background-color: white;
  box-sizing: border-box;
  border-radius: 5px !important;
  height: 50px;
  padding: 15px;
  transition: all .3s ease-in-out;
}


.ng-select.custom .ng-select-container  {            
  background-color: white;
  box-sizing: border-box;
  border-radius: 5px !important;
  height: 50px;
  padding: 6px;
  transition: all .3s ease-in-out;
}

.icon-btn-1{
  padding: 10px;
  background-color:#dfe0e1c0;
  border-radius: 100%;
  box-shadow: 0 2px 5px 0 rgb(178 179 245 / 69%), inset 0 1px 3px 0 rgb(178 179 245 / 10%);
}
.icon-btn-1:hover{
  background-color:#dfe0e1;
}



.home-page-search {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.home-page-search.fixed {
  top: 0px;
  height: auto;
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 5px 5px #9ebac536;
  padding: 1em 8em 3em 8em;
  transition: opacity .10s linear;
}


.admin-panel-wrapp {
  margin-top: 43px !important;
}

.page-link {
  padding: 0.7rem 0.95rem !important;
  font-size: 17px;
}

.capitalize{
  text-transform: capitalize;
}

.table-even-odd-wrapp table tr:hover {
  background-color: #F0F8FF !important;
}